/* eslint-disable */

import React from 'react';

export default function WireframeHeaderDesktop(props: {
    check24Domain: string;
    href: string;
    loginStatus: 'active' | 'expired' | 'guest' | 'loggedout';
    username: string | null;
    assetComponent: React.ReactNode;
    contactLayerComponent: React.ReactNode;
    navigationComponent: React.ReactNode;
}) {
    // @ts-ignore - not used in all components
    const trailingQuestionmarkRegex = new RegExp('\\?\\$');

    return (
        <>
            {props.assetComponent}
            <style
                jsx
                global
            >{`
                .header-inline-style-0 {
                    display: none !important;
                }

                .c24-customer a {
                    color: var(--color-endeavour);
                }
                .c24-search-input {
                    background-color: #fff;
                }
                /** how to test: Open Login Layer -> map button should be below blocking layer */
                .c24-blocking-layer {
                    z-index: var(--z-index-wireframeBlockingLayer);
                }
                #c24-mylists {
                    z-index: unset;
                }
            `}</style>
            <header data-nosnippet="true">
                <div
                    id="c24-header"
                    className={`c24-header c24-search-slim c24-nonav`}
                >
                    <div className={`c24-header-inner`}>
                        <div id="c24-header-top">
                            <div className={`c24-header-content clearfix`}>
                                <a
                                    href={`https://www.check24.de/`}
                                    className={`c24-logo`}
                                    title="CHECK24 - Deutschlands gr&ouml;&szlig;tes Vergleichsportal"
                                    tabIndex={0}
                                ></a>
                                <div className={`c24-header-components`}>
                                    <span
                                        className={`c24-search-icon c24-header-hover`}
                                        tabIndex={0}
                                    >
                                        <span className={`c24-search-icon-content`}></span>
                                    </span>
                                    <div className={`c24-search`}>
                                        <div className={`c24-search-corner`}></div>
                                        <div className={`c24-search-content`}>
                                            <form
                                                action="https://www.check24.de/suche/"
                                                method="get"
                                                acceptCharset="utf-8"
                                                id="c24-search-form"
                                                spellCheck="false"
                                            >
                                                <fieldset>
                                                    <input
                                                        type="text"
                                                        id="c24-search-header"
                                                        name="q"
                                                        className={`c24-search-input`}
                                                        placeholder="Was m&ouml;chten Sie vergleichen?"
                                                        defaultValue=""
                                                        tabIndex={0}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="source"
                                                        id="source_suggest"
                                                        defaultValue="input"
                                                    />
                                                    <div className={`c24-search-button`}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 89.0999984741211 102"
                                                        >
                                                            <path
                                                                d="M64.8,14.1C52.6-1.7,29.9-4.6,14.1,7.5S-4.6,42.4,7.5,58.2c11,14.2,30.3,17.9,45.6,9.9L77,99  c0.9,1.2,2.6,1.4,3.6,0.5l5.5-4.2c1.2-0.9,1.4-2.6,0.5-3.6L62.8,60.8C74.5,48,75.8,28.2,64.8,14.1z M52.9,57.8  c-11.9,9.2-29.1,7-38.3-5c-9.3-11.9-7-29.1,5-38.3c11.9-9.3,29.1-7,38.3,5C67.1,31.4,64.8,48.5,52.9,57.8z"
                                                                fill="#333333"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className={`c24-header-placeholder`}></div>
                                    <div
                                        className={`c24-contact c24-header-hover clearfix`}
                                        data-qa-id="qa-wireframe-contact"
                                    >
                                        <a
                                            className={`c24-contact-content clearfix`}
                                            href={`https://www.check24.de/unternehmen/kontakt/`}
                                            tabIndex={0}
                                        >
                                            <span className={`c24-contact-icon`}>
                                                <span className={`c24-contact-icon-svg`}></span>
                                            </span>
                                            <div className={`c24-phone`}>
                                                <div className={`c24-service-code`}></div>
                                                <div
                                                    className={`c24-phone-number`}
                                                    x-ms-format-detection="none"
                                                ></div>
                                                <div className={`c24-phone-help`}>Hilfe und Kontakt</div>
                                            </div>
                                        </a>
                                        <div
                                            className={`c24-contact-layer c24-header-hover-layer`}
                                            id="chatty-chatbot-layer"
                                        >
                                            {props.contactLayerComponent}
                                        </div>
                                    </div>
                                    <div className={`c24-notification`}>
                                        <a
                                            className={`c24-notification-icon c24-header-hover`}
                                            href={`https://kundenbereich.check24.de/?o=notifications`}
                                            tabIndex={0}
                                        >
                                            <span
                                                className={`c24-notification-icon-svg`}
                                                aria-label="Benachrichtigungen ansehen"
                                            ></span>
                                            <div className={`c24-notification-count`}></div>
                                        </a>
                                        <div className={`c24-notification-layer-hover`}>
                                            <div className={`c24-notification-layer`}>
                                                <div className={`content`}></div>
                                                <a
                                                    className={`all`}
                                                    href={`#`}
                                                ></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`c24-mylists c24-mylists-heart c24-mylists-product c24-mylists-home`}
                                        id="c24-mylists"
                                    >
                                        <a
                                            className={`c24-mylists-content clearfix`}
                                            href={`https://kundenbereich.check24.de/?o=lists`}
                                            tabIndex={0}
                                            aria-label="Merkzettel ansehen"
                                        >
                                            <span className={`c24-mylists-icon c24-header-hover`}>
                                                <span className={`c24-mylists-icon-svg`}></span>
                                            </span>
                                            <div className={`c24-mylists-count`}></div>
                                            <div className={`c24-mylists-label`}>Merkzettel</div>
                                        </a>
                                        <div className={`c24-mylists-layer c24-header-hover-layer`}>
                                            <div className={`c24-mylists-layer-content`}>
                                                <div className={`c24-mylists-available`}>
                                                    <div className={`c24-mylists-lists`}>
                                                        <div className={`c24-mylists-headline clearfix`}>
                                                            <span>Merkzettel</span>
                                                        </div>
                                                        <div className={`c24-mylists-lists-items`}></div>
                                                    </div>
                                                </div>
                                                <div className={`c24-mylists-no-items`}>
                                                    <div className={`c24-mylists-headline clearfix`}>
                                                        <span>Merkzettel</span>
                                                    </div>
                                                    Ihr Merkzettel ist leer.
                                                </div>
                                                <div className={`empty`}>
                                                    Bitte melden Sie sich in Ihrem Kundenkonto an, um Ihren Merkzettel
                                                    zu sehen.
                                                </div>
                                            </div>
                                            <a
                                                className={`all`}
                                                href={`#`}
                                            >
                                                <span></span>
                                            </a>
                                        </div>
                                    </div>

                                    {(props.loginStatus === 'guest' || props.loginStatus === 'loggedout') && (
                                        <div className={`c24-customer c24-customer-guest`}>
                                            <a
                                                className={`c24-customer-hover-wrapper c24-uliloginlayer-login-opener`}
                                                href={`https://kundenbereich.check24.de/user/account.html?o=overview`}
                                                tabIndex={0}
                                            >
                                                <span
                                                    className={`c24-customer-icon c24-customer-icon-lo c24-header-hover`}
                                                ></span>
                                                <span className={`c24-customer-hover c24-header-hover`}>Anmelden</span>
                                            </a>
                                            <div className={`c24-customer-layer c24-header-hover-layer`}>
                                                <a
                                                    id="c24-meinkonto-anmelden"
                                                    href={`https://kundenbereich.${props.check24Domain}/user/login?ref=${encodeURIComponent(props.href.replace(trailingQuestionmarkRegex, ''))}`}
                                                    className={`c24-button c24-meinkonto-reflink c24-meinkonto-login-link c24-uliloginlayer-login-opener`}
                                                >
                                                    anmelden
                                                </a>
                                                <div className={`c24-customer-salutation-text`}>
                                                    <span>Neuer Kunde? </span>
                                                    <a
                                                        href={`https://kundenbereich.${props.check24Domain}/user/register.html?ref=${encodeURIComponent(props.href.replace(trailingQuestionmarkRegex, ''))}`}
                                                        className={`c24-meinkonto-reflink c24-meinkonto-register-link c24-uliloginlayer-register-opener`}
                                                    >
                                                        Starten Sie hier.
                                                    </a>
                                                </div>
                                                <ul className={`c24-customer-options`}>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=overview`}
                                                        >
                                                            Mein Konto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=activitylist`}
                                                        >
                                                            Meine Aktivit&auml;ten
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://www.check24.de/messagecenter/`}>
                                                            Meine Nachrichten
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=cs_codes`}
                                                        >
                                                            Gutscheine &amp; Guthaben
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=points`}
                                                        >
                                                            CHECK24 Punkte
                                                        </a>
                                                    </li>
                                                </ul>
                                                <ul className={`c24-customer-center`}>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/insurance-center.html`}
                                                        >
                                                            <span>Versicherungscenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/credit-center.html`}>
                                                            <span>Kreditcenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://geldanlage.check24.de/ga-center`}>
                                                            <span>Geldanlagecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/internet-center.html`}
                                                        >
                                                            <span>Internetcenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/energy-center.html`}>
                                                            <span>Energiecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/financing-center.html`}
                                                        >
                                                            <span>Baufinanzierungscenter</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <ul className={`c24-customer-center`}>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/travel-center.html`}>
                                                            <span>Reisecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://community.check24.de/`}>
                                                            <span>Reise Community</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    {props.loginStatus === 'active' && (
                                        <div className={`c24-customer c24-customer-user`}>
                                            <a
                                                className={`c24-customer-hover-wrapper`}
                                                href={`https://kundenbereich.check24.de/user/account.html?o=overview`}
                                                tabIndex={0}
                                            >
                                                <span
                                                    className={`c24-customer-icon c24-customer-icon-li c24-header-hover`}
                                                ></span>
                                                <span className={`c24-customer-hover c24-header-hover`}>
                                                    {props.username}{' '}
                                                </span>
                                            </a>
                                            <div className={`c24-customer-layer c24-header-hover-layer`}>
                                                <a
                                                    id="c24-meinkonto-anmelden"
                                                    href={`https://kundenbereich.${props.check24Domain}/user/login?ref=${encodeURIComponent(props.href.replace(trailingQuestionmarkRegex, ''))}`}
                                                    className={`c24-button c24-meinkonto-reflink c24-meinkonto-login-link c24-uliloginlayer-login-opener header-inline-style-0`}
                                                >
                                                    Anmelden
                                                </a>
                                                <ul className={`c24-customer-options`}>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=overview`}
                                                        >
                                                            Mein Konto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=activitylist`}
                                                        >
                                                            Meine Aktivit&auml;ten
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://www.check24.de/messagecenter/`}>
                                                            Meine Nachrichten
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=cs_codes`}
                                                        >
                                                            Gutscheine &amp; Guthaben
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=points`}
                                                        >
                                                            CHECK24 Punkte
                                                        </a>
                                                    </li>
                                                </ul>
                                                <ul className={`c24-customer-center`}>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/insurance-center.html`}
                                                        >
                                                            <span>Versicherungscenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/credit-center.html`}>
                                                            <span>Kreditcenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://geldanlage.check24.de/ga-center`}>
                                                            <span>Geldanlagecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/internet-center.html`}
                                                        >
                                                            <span>Internetcenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/energy-center.html`}>
                                                            <span>Energiecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/financing-center.html`}
                                                        >
                                                            <span>Baufinanzierungscenter</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <ul className={`c24-customer-center`}>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/travel-center.html`}>
                                                            <span>Reisecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://community.check24.de/`}>
                                                            <span>Reise Community</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className={`c24-customer-check`}>
                                                    <a
                                                        href={`https://kundenbereich.${props.check24Domain}/user/logout.html?ref=${encodeURIComponent(props.href.replace(trailingQuestionmarkRegex, ''))}`}
                                                        title="Abmelden"
                                                    >
                                                        Abmelden
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {props.loginStatus === 'expired' && (
                                        <div className={`c24-customer c24-customer-expired`}>
                                            <a
                                                className={`c24-customer-hover-wrapper c24-uliloginlayer-login-opener`}
                                                href={`https://kundenbereich.check24.de/user/account.html?o=overview`}
                                                tabIndex={0}
                                            >
                                                <span
                                                    className={`c24-customer-icon c24-customer-icon-li c24-header-hover`}
                                                ></span>
                                                <span className={`c24-customer-hover c24-header-hover`}>
                                                    {props.username}{' '}
                                                </span>
                                            </a>
                                            <div className={`c24-customer-layer c24-header-hover-layer`}>
                                                <ul className={`c24-customer-options`}>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=overview`}
                                                        >
                                                            Mein Konto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=activitylist`}
                                                        >
                                                            Meine Aktivit&auml;ten
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://www.check24.de/messagecenter/`}>
                                                            Meine Nachrichten
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=cs_codes`}
                                                        >
                                                            Gutscheine &amp; Guthaben
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/user/account.html?o=points`}
                                                        >
                                                            CHECK24 Punkte
                                                        </a>
                                                    </li>
                                                </ul>
                                                <ul className={`c24-customer-center`}>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/insurance-center.html`}
                                                        >
                                                            <span>Versicherungscenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/credit-center.html`}>
                                                            <span>Kreditcenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://geldanlage.check24.de/ga-center`}>
                                                            <span>Geldanlagecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/internet-center.html`}
                                                        >
                                                            <span>Internetcenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/energy-center.html`}>
                                                            <span>Energiecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`https://kundenbereich.check24.de/financing-center.html`}
                                                        >
                                                            <span>Baufinanzierungscenter</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <ul className={`c24-customer-center`}>
                                                    <li>
                                                        <a href={`https://kundenbereich.check24.de/travel-center.html`}>
                                                            <span>Reisecenter</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`https://community.check24.de/`}>
                                                            <span>Reise Community</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className={`c24-customer-check`}>
                                                    <a
                                                        href={`https://kundenbereich.${props.check24Domain}/user/logout.html?ref=${encodeURIComponent(props.href.replace(trailingQuestionmarkRegex, ''))}`}
                                                        title="Abmelden"
                                                    >
                                                        Abmelden
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className={`c24-customer-trigger`}
                                        id="c24-customer-trigger"
                                    >
                                        <div
                                            className={`c24-customer-layer c24-header-hover-layer c24-ignore-dc-event`}
                                        >
                                            <div className={`c24-customer-trigger-close`}>
                                                <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 357 357"
                                                    xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <polygon
                                                            fill="#b4b4b4"
                                                            points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 214.2,178.5"
                                                        ></polygon>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className={`c24-customer-trigger-text`}></div>
                                            <a
                                                className={`c24-button c24-customer-login-opener c24-uliloginlayer-login-opener`}
                                                href={`#`}
                                            >
                                                anmelden
                                            </a>
                                            <div className={`c24-customer-salutation-text`}>
                                                <span>Neuer Kunde? </span>
                                                <a
                                                    className={`c24-meinkonto-reflink c24-customer-register-opener c24-uliloginlayer-register-opener`}
                                                    href={`#`}
                                                >
                                                    Starten Sie hier.
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
